<template>
  <div>
    <!-- Início principal -->
    <v-card v-if="modo == 'L'">
      <v-toolbar flat>
        <v-icon> fas fa-cart-arrow-down </v-icon>
        <v-toolbar-title class="pl-4">
          Pré-venda
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="close">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Inicio filtros -->
        <v-row align="center">
          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_inicio"
              v-model="modal.dataInicial"
              :return-value.sync="dataInicial"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data inicial"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateInicioGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataInicial" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.dataInicial = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_inicio.save(dataInicial)">OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col cols="2" class="alinhamento">
            <v-dialog
              ref="modal_data_final"
              v-model="modal.dataFinal"
              :return-value.sync="dataFinal"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  prepend-icon="fas fa-calendar-alt"
                  label="Data Final"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDateFinalGrid"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dataFinal" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.dataFinal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_final.save(dataFinal)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="1"
            style="padding: 0 0 0 12px !important; max-width: 50px !important">
            <v-app-bar-nav-icon @click="changePage(1)">
              <v-icon>fas fa-search</v-icon>
            </v-app-bar-nav-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.prevenda.cabecalho"
              :items="grid.prevenda.items"
              :items-per-page="grid.prevenda.porPagina"
              hide-default-footer
              class="elevation-1"
              :loading="grid.prevenda.carregando"
            >
              <template v-slot:item.operacoes="{ item }">
                <v-icon size="16" color="green" class="operacoes" @click="editPreVenda(item)">fas fa-pen</v-icon>
              </template>

            </v-data-table>
          </v-col>
        </v-row>
        <!-- <v-row
            class="mt-4"
        >
          <v-col>
            <v-pagination
                v-model="grid.prevenda.pagination.page"
                :length="Math.ceil(this.grid.prevenda.pagination.totalRegistros / this.grid.prevenda.pagination.size)"
                @input="changePage"
                :total-visible="5"
            ></v-pagination>
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>
    <!-- Fim principal -->

    <!-- Início editar -->
    <v-card v-else>
      <v-toolbar flat>
        <v-icon> fas fa-cart-arrow-down </v-icon>
        <v-toolbar-title class="pl-4">
          Cadastro de Nota e Código de rastreio
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="closeModal">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col class="px-6 py-6">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Código Pedido
                    </th>
                    <th class="text-left">
                      Data Venda
                    </th>
                    <th class="text-left">
                      Transportadora
                    </th>
                    <th class="text-left">
                      Serviço de entrega
                    </th>
                    <th class="text-left">
                      Canal
                    </th>
                    <th class="text-left">
                      Subcanal
                    </th>
                    <th class="text-left">
                      Base
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="obj in item"
                    :key="obj.numeroDoPedido"
                  >
                    <td>{{ obj.numeroDoPedido }}</td>
                    <td>{{ obj.dataVenda }}</td>
                    <td>{{ obj.transportadoraNome }}</td>
                    <td>{{ obj.servicoEntrega }}</td>
                    <td>{{ obj.canalVenda }}</td>
                    <td>{{ obj.subCanal }}</td>
                    <td>{{ obj.base }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 ml-2 pb-0">
            <v-text-field
              dense
              outlined
              label="Código de rastreio"
              type="text"
              hide-details
              v-model="prevenda.codigoRastreio"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 ml-2 pb-0">
            <v-dialog
              ref="modal_data_emissao_nota"
              v-model="modal.dataEmissaoNota"
              :return-value.sync="prevenda.dataEmissaoNota"
              persistent
              width="290px"
              :retain-focus="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  label="Data emissão nota"
                  v-bind="attrs"
                  v-on="on"
                  :value="formatDataEmissaoForm"
                  clearable
                  hide-details
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker v-model="prevenda.dataEmissaoNota" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal.dataEmissaoNota = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  text
                  color="primary"
                  @click="$refs.modal_data_emissao_nota.save(prevenda.dataEmissaoNota)"
                  >OK</v-btn
                >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 ml-2 pb-0">
            <v-text-field
              dense
              outlined
              label="Série nota"
              type="text"
              hide-details
              v-model="prevenda.serieNota"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 ml-2 pb-0">
            <v-text-field
              dense
              outlined
              label="Número nota"
              type="text"
              hide-details
              v-model="prevenda.numeroNota"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-3 ml-2 pb-0">
            <v-text-field
              dense
              outlined
              label="Chave NFE"
              type="text"
              hide-details
              v-model="prevenda.chaveNFE"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col style="text-align: right" class="mr-6">
            <v-btn
              depressed
              color="error"
              class="mr-2"
              @click="closeModal"
            >
              Cancelar
            </v-btn>
            <v-btn
              depressed
              color="success"
              @click="saveStatus"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim editar -->
    
  </div>
</template>

<script>
import { baseApiUrlPersys } from "@/global";
import axios from "axios";
import Template from "../components/Template.vue";
import moment from "moment";


export default {
  components: {
    Template,
  },
  name: "PreVenda",
  data: () => ({
    modo: 'L',
    dataInicial: moment (new Date(new Date().getTime()).setDate(new Date().getDate() - 7)).format("YYYY-MM-DD"),
    dataFinal: moment(new Date()).format("YYYY-MM-DD"),
    cod_empresa: '',
    grid: {
      prevenda: {
        cabecalho: [
          { text: "Código Pedido", value: "numeroDoPedido", sortable: false },
          { text: "Data Venda", value: "dataVenda", sortable: false },
          { text: "Transportadora", value: "transportadoraNome", sortable: false },
          { text: "Serviço de entrega", value: "servicoEntrega", sortable: false },
          { text: "Canal", value: "canalVenda", sortable: false },
          { text: "Subcanal", value: "subCanal", sortable: false },
          { text: "Base", value: "base", sortable: false },
          { text: "", value: "operacoes", sortable: false },
        ],
        items: [],
        carregando: false,
        porPagina: 10,
        pagination: {
          page: 0,
          size: 500,
          totalRegistros: 0,
        }
      },
    },
    modal: {
      dataInicial: false,
      dataFinal: false,
      dataEmissaoNota: false,
    },
    prevenda: {
      codigoRastreio: '',
      dataEmissaoNota: '',
      serieNota: '',
      numeroNota: '',
      chaveNFE: '',
    },
    item: [],
  }),
  created() {
    this.cod_empresa = Number(this.$store.state.cod_empresa);
  },
  mounted() {
    this.changePage(1);
  },
  methods: {
    close() {
      this.$router.push("/");
    },
    async loadPreVenda() {

      this.grid.prevenda.items = [];

      this.grid.prevenda.carregando = true;

      let url = `${baseApiUrlPersys}/order/pre_venda/${this.cod_empresa}?fim=${this.dataFinal}&inicio=${this.dataInicial}&page=${this.grid.prevenda.pagination.page - 1}&size=${this.grid.prevenda.pagination.size}`;
      await axios
        .get(url)
        .then((res) => {
          this.grid.prevenda.pagination.totalRegistros = res.data.numeroTotalPedidos;
          this.grid.prevenda.items = res.data.availableOrder;
          this.grid.prevenda.porPagina = res.data.availableOrder.length;
          for (let i = 0; i < res.data.availableOrder.length; i++) {
            this.grid.prevenda.items[i].dataVenda = this.grid.prevenda.items[i].dataVenda? moment(this.grid.prevenda.items[i].dataVenda).format("DD/MM/YYYY"): "";
          }
          this.grid.prevenda.carregando = false;
        });
      this.clearFields();
    },
    editPreVenda(item) {
      this.item = [];
      this.item.push(item);
      this.modo = 'E';
    },
    closeModal() {
      this.modo = 'L';
      this.clearFields();
    },
    async saveStatus() {
      if (this.prevenda.codigoRastreio == '') {
        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'error',
          title: 'O campo Código de rastreio é obrigatório.',
          showConfirmButton: false,
          timer: 2500
        });
      } else {
        let aux = {
          chaveNfe: this.prevenda.chaveNFE,
          dataEmissaoNota: this.prevenda.dataEmissaoNota != "" ? moment(this.prevenda.dataEmissaoNota).format("DDMMYYYY 00:00:00.000") : "",
          numeroNota: this.prevenda.numeroNota,
          numeroObjeto: this.prevenda.codigoRastreio,
          serieNota: this.prevenda.serieNota,
        };

        let url = `${baseApiUrlPersys}/order/status/${this.cod_empresa}/${this.item[0].numeroDoPedido}`;
        await axios
          .post(url, aux)
          .then((res) => {
            if(res.status == 200) {
              this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'success',
                title: 'Dados atualizados com sucesso !',
                showConfirmButton: false,
                timer: 2500
              });
              this.modo = 'L';
              this.changePage(1);
            } else {
              this.$swal({
                toast: true,
                position: 'top-end',
                icon: 'error',
                title: 'Whoops, houve um problema ao atualizar o status do pedido.',
                showConfirmButton: false,
                timer: 2500
              });
            }
          });
      }
    },
    changePage(page) {
      this.grid.prevenda.pagination.page = page;
      this.loadPreVenda();
    },
    clearFields() {
      this.prevenda.codigoRastreio = '';
      this.prevenda.dataEmissaoNota = '';
      this.prevenda.serieNota = '';
      this.prevenda.numeroNota = '';
      this.prevenda.chaveNFE = '';
    }
  },
  computed: {
    formatDateInicioGrid() {
      return this.dataInicial
        ? moment(this.dataInicial).format("DD/MM/YYYY")
        : "";
    },
    formatDateFinalGrid() {
      return this.dataFinal ? moment(this.dataFinal).format("DD/MM/YYYY") : "";
    },
    formatDataEmissaoForm() {
      return this.prevenda.dataEmissaoNota ? moment(this.prevenda.dataEmissaoNota).format("DD/MM/YYYY") : "";
    },
  }
};
</script>
<style scoped>
.operacoes {
  cursor: pointer;
  justify-content: flex-end;
  padding-right: 8px;
}
.operacoes::before{
  background-color: transparent !important;
}

.operacoes::after{
  background-color: transparent !important;
}

.alignmentDialog, .btnOperacoes {
  display: flex;
  justify-content: flex-end
}
.alignmentDialogDelete{
  display: flex;
  justify-content: center;
}

.v-icon.v-icon::after{
  display: none;
}

.alinhamento {
  padding-bottom: 0 !important;
  padding-top: 25px !important;
}
</style>
